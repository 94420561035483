import { SquadService } from "../../gen/proto/squads/squads_connect";
import * as squads_pb from "../../gen/proto/squads/squads_pb";

import { parseObservations } from "@/api/devices";
import * as clients_pb from "../../gen/proto/clients/clients_pb";

import clientService from "@/api/client";
import { streamHandler } from "@/api/stream";
import { transport } from "@/api/transport";
import {
    fromProtoOrgId,
    fromProtoSquadId,
    fromProtoTimestamp,
    fromProtoUserId,
    pbOrgId,
    pbPersonId,
    pbSquadId,
    pbSquadSet,
    pbTimestamp,
    pbUserId,
    toProtoOrgSet,
    toProtoSquadSet,
} from "@/api/util";
import * as d from "@/domain/domain";
import { UserObservation } from "@/domain/presence";
import {
    OrgOverviewOrDeletedSchema,
    SquadActivityOrDeletedSchema,
    SquadOverViewOrDeletedSchema,
} from "@/domain/squads";
import { UserDefinition, UserDefinitionSchema } from "@/domain/users";
import { deepFreeze } from "@/misc/deepFreeze";
import { translateAsyncIterable } from "@/misc/iterable";
import { Diff, Optional } from "@/misc/types";
import { createClient } from "@connectrpc/connect";
import { parseMessageWithSchema } from "./parser";

export const squadsService = createClient(SquadService, transport);
export default squadsService;

interface CreateOrgArgs {
    name: string;
    initialPerson: d.PersonId;
}
export async function createOrg(args: CreateOrgArgs): Promise<d.OrgId> {
    const resp = await squadsService.createOrg(
        new squads_pb.CreateOrgRequest({
            name: args.name,
            initialPersonId: pbPersonId(args.initialPerson),
        }),
    );
    return fromProtoOrgId(resp.newOrgId);
}

export interface CreateUserArgs {
    orgId: d.OrgId;
    personId: d.PersonId;
}
export async function createUser(args: CreateUserArgs): Promise<void> {
    await squadsService.createUser(
        new squads_pb.CreateUserRequest({
            orgId: pbOrgId(args.orgId),
            personId: pbPersonId(args.personId),
        }),
    );
}

interface CreateSquadArgs {
    orgId: d.OrgId;
    name: string;
    initialUser: d.UserId;
}
export async function createSquad(args: CreateSquadArgs): Promise<d.SquadId> {
    const resp = await squadsService.createSquad(
        new squads_pb.CreateSquadRequest({
            orgId: pbOrgId(args.orgId),
            name: args.name,
            initialUserId: pbUserId(args.initialUser),
        }),
    );
    return fromProtoSquadId(resp.newSquadId);
}

export interface AddSquadMemberArgs {
    squadId: d.SquadId;
    userId: d.UserId;
}
export async function addSquadMember(args: AddSquadMemberArgs): Promise<void> {
    await squadsService.addSquadMember(
        new squads_pb.AddSquadMemberRequest({
            squadId: pbSquadId(args.squadId),
            userId: pbUserId(args.userId),
        }),
    );
}

export interface RemoveSquadMemberArgs {
    squadId: d.SquadId;
    userId: d.UserId;
}
export async function removeSquadMember(args: RemoveSquadMemberArgs): Promise<void> {
    await squadsService.removeSquadMember(
        new squads_pb.RemoveSquadMemberRequest({
            squadId: pbSquadId(args.squadId),
            userId: pbUserId(args.userId),
        }),
    );
}

export type SetSquadLastReadArgs = {
    squadIds: d.SquadId[];
    userId: d.UserId;
    lastRead: d.Timestamp;
};
export async function setSquadLastRead(args: SetSquadLastReadArgs): Promise<void> {
    await squadsService.setSquadLastRead(
        new squads_pb.SetSquadLastReadRequest({
            squadIds: pbSquadSet(args.squadIds),
            userId: pbUserId(args.userId),
            lastRead: pbTimestamp(args.lastRead),
        }),
    );
}

export type UserSquadLastRead = {
    squadId: d.SquadId;
    userId: d.UserId;
    lastRead: d.Timestamp;
};

function lastReadsParser(res: squads_pb.SubUserSquadLastReadsResponse): UserSquadLastRead {
    const uslr: UserSquadLastRead = {
        squadId: fromProtoSquadId(res.squadId),
        userId: fromProtoUserId(res.userId),
        lastRead: fromProtoTimestamp(res.lastRead),
    };
    return deepFreeze(uslr);
}

function translateUserSquadLastReads(strUserId: d.UserId) {
    const userId = pbUserId(strUserId);

    return async function* (v: Diff<d.SquadId>) {
        if (v.added?.length) {
            yield new squads_pb.SubUserSquadLastReadsRequest({
                squadIds: toProtoSquadSet(v.added),
                userId,
                addToSub: true,
            });
        }
        if (v.removed?.length) {
            yield new squads_pb.SubUserSquadLastReadsRequest({
                squadIds: toProtoSquadSet(v.removed),
                userId,
                addToSub: false,
            });
        }
    };
}

export async function* subUserSquadLastReads(
    reqStream: AsyncIterableIterator<Diff<d.SquadId>>,
    userId: d.UserId,
    signal: AbortSignal,
) {
    const translation = translateAsyncIterable(reqStream, translateUserSquadLastReads(userId));

    const stream = squadsService.subUserSquadLastReads(translation, { signal });

    yield* streamHandler(stream, lastReadsParser, subUserSquadLastReads.name);
}

async function* translateOrgOverviewDiff(v: Diff<d.OrgId>) {
    if (v.added?.length) {
        yield new squads_pb.SubOrgOverviewsRequest({
            orgIds: toProtoOrgSet(v.added),
            addToSub: true,
        });
    }
    if (v.removed?.length) {
        yield new squads_pb.SubOrgOverviewsRequest({
            orgIds: toProtoOrgSet(v.removed),
            addToSub: false,
        });
    }
}

export async function* subOrgOverviews(
    reqStream: AsyncIterableIterator<Diff<d.OrgId>>,
    signal: AbortSignal,
) {
    const translation = translateAsyncIterable(reqStream, translateOrgOverviewDiff);

    const stream = squadsService.subOrgOverviews(translation, { signal });

    yield* streamHandler(
        stream,
        parseMessageWithSchema(OrgOverviewOrDeletedSchema, "orgOverviewOrDeleted"),
        subOrgOverviews.name,
    );
}

async function* translateSquadOverviewsDiff(v: Diff<d.SquadId>) {
    if (v.added?.length) {
        yield new squads_pb.SubSquadOverviewsRequest({
            squadIds: toProtoSquadSet(v.added),
            addToSub: true,
        });
    }
    if (v.removed?.length) {
        yield new squads_pb.SubSquadOverviewsRequest({
            squadIds: toProtoSquadSet(v.removed),
            addToSub: false,
        });
    }
}

export async function* subSquadOverviews(
    reqStream: AsyncIterableIterator<Diff<d.SquadId>>,
    signal: AbortSignal,
) {
    const translation = translateAsyncIterable(reqStream, translateSquadOverviewsDiff);

    const stream = squadsService.subSquadOverviews(translation, { signal });

    yield* streamHandler(
        stream,
        parseMessageWithSchema(SquadOverViewOrDeletedSchema, "squadOverviewOrDeleted"),
        subSquadOverviews.name,
    );
}

export type RenameSquadArgs = {
    squadId: d.SquadId;
    newName: string;
};
export async function renameSquad(args: RenameSquadArgs): Promise<void> {
    await squadsService.renameSquad(
        new squads_pb.RenameSquadRequest({
            squadId: pbSquadId(args.squadId),
            newName: args.newName,
        }),
    );
}

export async function listUsersByPerson(
    personId: d.PersonId,
): Promise<Array<UserDefinition>> {
    const resp = await squadsService.listUsersByPerson(
        new squads_pb.ListUsersByPersonRequest({
            personId: pbPersonId(personId),
        }),
    );

    return resp.userDefinitions.map(parseMessageWithSchema(UserDefinitionSchema)) || [];
}

export interface ListPersonOrgsResponse {
    orgIds: Array<d.OrgId>;
}

export async function listPersonOrgs(
    personId: d.PersonId,
): Promise<ListPersonOrgsResponse> {
    const resp = await squadsService.listPersonOrgs(
        new squads_pb.ListPersonOrgsRequest({
            personId: pbPersonId(personId),
        }),
    );

    return {
        orgIds: resp.orgIds?.map(fromProtoOrgId) || [],
    };
}

export interface ListUserSquadsResponse {
    squadIDs: Array<d.SquadId>;
}

async function* translateSubUserSquadListsDiff(v: Diff<d.UserId>) {
    if (v.added?.length) {
        yield new squads_pb.SubUserSquadListsRequest({
            userIds: {
                ids: v.added.map(pbUserId),
            },
            addToSub: true,
        });
    }
    if (v.removed?.length) {
        yield new squads_pb.SubUserSquadListsRequest({
            userIds: { ids: v.removed.map(pbUserId) },
            addToSub: false,
        });
    }
}

export async function* subUserSquadLists(
    reqStream: AsyncIterableIterator<Diff<d.UserId>>,
    signal: AbortSignal,
): AsyncGenerator<Readonly<d.SquadId[]>, void, unknown> {
    const translation = translateAsyncIterable(reqStream, translateSubUserSquadListsDiff);

    const resp = squadsService.subUserSquadLists(translation, { signal });

    const parse = (res: squads_pb.SubUserSquadListsResponse) =>
        res.squadIds?.map(fromProtoSquadId) || [];

    yield* streamHandler(resp, parse, subUserSquadLists.name);
}

async function* translateSquadLatestActivity(v: Diff<d.SquadId>) {
    if (v.added?.length) {
        yield new squads_pb.SubSquadLatestActivityRequest({
            squadIds: toProtoSquadSet(v.added),
            addToSub: true,
        });
    }
    if (v.removed?.length) {
        yield new squads_pb.SubSquadLatestActivityRequest({
            squadIds: toProtoSquadSet(v.removed),
            addToSub: false,
        });
    }
}

export async function* subSquadActivity(
    reqStream: AsyncIterableIterator<Diff<d.SquadId>>,
    signal: AbortSignal,
) {
    const translation = translateAsyncIterable(reqStream, translateSquadLatestActivity);

    const stream = squadsService.subSquadLatestActivity(translation, { signal });

    yield* streamHandler(
        stream,
        parseMessageWithSchema(SquadActivityOrDeletedSchema, "activityOrDeleted"),
        subSquadActivity.name,
    );
}

export type SquadObservations = {
    viewId: d.SquadId;
    observations: Array<UserObservation>;
};

async function* translateSquadObserversDiff(v: Diff<d.SquadId>) {
    if (v.added?.length) {
        yield new squads_pb.SubSquadObserversRequest({
            squadIds: toProtoSquadSet(v.added),
            addToSub: true,
        });
    }
    if (v.removed?.length) {
        yield new squads_pb.SubSquadObserversRequest({
            squadIds: toProtoSquadSet(v.removed),
            addToSub: false,
        });
    }
}

function parseSquadObservers(pb: Optional<squads_pb.SubSquadObserversResponse>): SquadObservations {
    return {
        viewId: fromProtoSquadId(pb?.squadId),
        observations: parseObservations(pb?.observers),
    };
}

export async function* subSquadObservers(
    reqStream: AsyncIterableIterator<Diff<d.SquadId>>,
    signal: AbortSignal,
): AsyncGenerator<SquadObservations, void, unknown> {
    const translation = translateAsyncIterable(reqStream, translateSquadObserversDiff);

    const resp = squadsService.subSquadObservers(translation, { signal });

    yield* streamHandler(resp, parseSquadObservers, subSquadObservers.name);
}

export async function* subFollowedUnreadBondsCount(
    userId: d.UserId,
    signal: AbortSignal,
): AsyncGenerator<number, void, unknown> {
    const req = new squads_pb.SubFollowedUnreadCountRequest({
        userId: pbUserId(userId),
    });

    const resp = squadsService.subFollowedUnreadCount(req, { signal });

    yield* streamHandler(
        resp,
        res => res.followedUnreadCount,
        subFollowedUnreadBondsCount.name,
    );
}

// region invites

interface InviteUserToSquadViaEmailArgs {
    squadId: d.SquadId;
    invitedEmailAddress: string;
}

export async function inviteUserToSquadViaEmail(
    args: InviteUserToSquadViaEmailArgs,
): Promise<void> {
    const req = new clients_pb.InviteUserToSquadViaEmailRequest({
        squadId: pbSquadId(args.squadId),
        invitedEmailAddress: args.invitedEmailAddress,
    });
    await clientService.inviteUserToSquadViaEmail(req);
}
