import { z } from "zod";

import * as bonds_pb from "../../gen/proto/bonds/bonds_pb";
import * as squads_pb from "../../gen/proto/squads/squads_pb";

import { parseBufMessage } from "@/api/parser";
import * as d from "@/domain/domain";

// Frontend only

export type PendingEmailMember = `urn:beyond:email:${string}`;
export type PendingEmailOverview = { type: "email"; id: PendingEmailMember; };

export const asPendingEmailMember = (email: string): PendingEmailMember => {
    if (email.includes(":")) {
        throw new Error(`Invalid email: ${email}`);
    }
    return `urn:beyond:email:${email}`;
};

export const asPendingEmailOverview = (member: PendingEmailMember): PendingEmailOverview => ({
    type: "email",
    id: member,
});

export const isPendingEmail = (
    member: PendingEmailMember | string,
): member is PendingEmailMember => {
    return member.startsWith("urn:beyond:email:") && member.split(":").length == 4;
};

export const isPendingEmailOverview = (
    overview: PendingEmailOverview | any,
): overview is PendingEmailOverview =>
    "type" in overview && overview.type == "email"
    && "id" in overview && isPendingEmail(overview.id);

export const extractPendingEmail = (member: PendingEmailMember): string => member.split(":")[3];

// Shared with backend

type InviteePlan = { case: "email"; email: string; } | { case: "personId"; personId: d.PersonId; };
const InviteeSchema = z.object({
    via: z.discriminatedUnion("case", [
        z.object({
            case: z.literal("email"),
            value: z.object({ email: z.string() }).transform(x => x.email),
        }),
        z.object({
            case: z.literal("personId"),
            value: z.object({ personId: d.personIdSchema }).transform(x => x.personId),
        }),
    ]),
}).transform((input): InviteePlan => {
    if (input.via.case === "email") {
        return { case: "email", email: input.via.value };
    }
    else {
        return { case: "personId", personId: input.via.value };
    }
});
export type Invitee = z.infer<typeof InviteeSchema>;

type InviteTargetPlan = { case: "common"; } | { case: "invitee"; invitee: Invitee; };
const InviteTargetSchema = z.discriminatedUnion("case", [
    z.object({
        case: z.literal("isCommon"),
        // z.object({ isCommon: z.literal(true) }).transform(x => x.isCommon)
    }),
    z.object({
        case: z.literal("invitee"),
        value: z.object({ invitee: InviteeSchema }).transform(x => x.invitee),
    }),
]).transform((input): InviteTargetPlan => {
    if (input.case === "isCommon") {
        return { case: "common" };
    }
    else {
        return { case: "invitee", invitee: input.value };
    }
});
export type InviteTarget = z.infer<typeof InviteTargetSchema>;

type ExpiryPlan = { expired: true; } | { expired: false; expiresAt: d.Timestamp; };
const ExpirySchema = z.discriminatedUnion("case", [
    z.object({
        case: z.literal("expired"),
        value: z.object({ expired: z.literal(true) }).transform(x => x.expired),
    }),
    z.object({
        case: z.literal("expiresAt"),
        value: z.object({ expiresAt: d.timestampSchema }).transform(x => x.expiresAt),
    }),
]).transform((input): ExpiryPlan => {
    if (input.case === "expired") {
        return { expired: true };
    }
    else {
        return { expired: false, expiresAt: input.value };
    }
});

const InviteBodySchema = z.object({
    opaqueCode: z.string(),
    originatorId: d.userIdSchema,
    expiry: ExpirySchema,
    createdAt: d.timestampSchema,
    target: InviteTargetSchema,
    accepted: z.boolean(),
    rejected: z.boolean(),
});

export const BondInviteSchema = z.object({
    bondId: d.bondIdSchema,
    body: InviteBodySchema,
});
export type BondInvite = z.infer<typeof BondInviteSchema>;

export const SquadInviteSchema = z.object({
    squadId: d.squadIdSchema,
    body: InviteBodySchema,
});
export type SquadInvite = z.infer<typeof SquadInviteSchema>;

export function translateBondInvite(bi: bonds_pb.BondInvite): BondInvite {
    return BondInviteSchema.parse(parseBufMessage(bi));
}

export function translateSquadInvite(si: squads_pb.SquadInvite): SquadInvite {
    return SquadInviteSchema.parse(parseBufMessage(si));
}
