import { FeatureFlagged } from "@/components/FeatureFlags";
import SensitiveText from "@/components/gui/SensitiveText";
import TimeAgo from "@/components/gui/TimeAgo";
import * as d from "@/domain/domain";
import { isCallStartMessage, isOfficialChatMessage } from "@/domain/messages";
import {
    AttachmentSearchResult,
    BondSearchResult,
    CallSearchResult,
    isAttachmentSearchResult,
    isBondSearchResult,
    isCallSearchResult,
    isMessageSearchResult,
    MessageSearchResult,
    SearchResult,
} from "@/domain/search";
import { viewUtils } from "@/domain/views";
import { selectBondById, selectBondTitle } from "@/features/bonds";
import { selectCallById } from "@/features/calls";
import { selectMessage } from "@/features/chats";
import { selectFilter, selectHighlightMarks, selectResults } from "@/features/search";
import { SearchFilterOptions } from "@/features/search";
import { selectUserName } from "@/features/users";
import {
    useInterestedBond,
    useInterestedCalls,
    useInterestedChannel,
} from "@/hooks/interest/useInterest";
import useViewStackNavigate from "@/hooks/navigation/useViewStackNavigate";
import useSelectorArgs from "@/hooks/useSelectorArgs";
import { isMobileBrowser } from "@/misc/mobile";
import { useAppSelector } from "@/store/redux";
import classNames from "classnames";
import React from "react";
import { CardContainer } from "./BondsListView";

function parseHTML(str: string, marks: [string, string]): JSX.Element[] {
    // mark is of the format [hl: random]. We should escape the brackets and the colon for valid regex.
    if (marks[0].length === 0 || marks[1].length === 0) {
        throw new Error("No marks provided.");
    }
    const escapeMe = /[-/\\^$*+?.()|[\]{}]/g;
    const start = marks[0].replace(escapeMe, "\\$&");
    const stop = marks[1].replace(escapeMe, "\\$&");
    const rex = new RegExp(start + "|" + stop, "g");
    const splitStrings = str.split(rex);

    const parsedStrings = splitStrings.map((s, i) => {
        if (i % 2 === 0) {
            return <SensitiveText key={i}>{s}</SensitiveText>;
        }
        else {
            return (
                <mark key={i} className="c-bond-card__mark">
                    <SensitiveText>{s}</SensitiveText>
                </mark>
            );
        }
    });

    return parsedStrings;
}

function getNoResultsMessage(filter: SearchFilterOptions): string {
    switch (filter) {
        case SearchFilterOptions.Messages:
            return "No messages found.";
        case SearchFilterOptions.Bonds:
            return "No bonds found.";
        case SearchFilterOptions.Calls:
            return "No calls found.";
        case SearchFilterOptions.Attachments:
            return "No attachments found.";
        default:
            return "No search results found.";
    }
}

function getNoResultsCard(filter: SearchFilterOptions): React.JSX.Element {
    return (
        <div className="c-search-notification">
            {getNoResultsMessage(filter)}
        </div>
    );
}

export function SearchView(): React.JSX.Element {
    const marks = useAppSelector(selectHighlightMarks);
    const selectedFilter = useAppSelector(selectFilter);
    const results = useAppSelector(selectResults);
    const filteredResults = results?.filter(result => filterMatches(result, selectedFilter)) ?? [];
    const noResultsMsg = getNoResultsCard(selectedFilter);

    return (
        <FeatureFlagged
            className="c-content"
            flag="search-feature-frontend"
            match={true}
        >
            {filteredResults?.length > 0 ? (
                <CardContainer>
                    {filteredResults.map((result, i) => (
                        <SearchResultMultiplex result={result} key={i} marks={marks} />
                    ))}
                </CardContainer>
            ) : noResultsMsg}
        </FeatureFlagged>
    );
}

function filterMatches(result: SearchResult, filter: SearchFilterOptions): boolean {
    if (filter === SearchFilterOptions.Everything) return true;
    if (isMessageSearchResult(result)) return filter === SearchFilterOptions.Messages;
    if (isBondSearchResult(result)) return filter === SearchFilterOptions.Bonds;
    if (isCallSearchResult(result)) return filter === SearchFilterOptions.Calls;
    if (isAttachmentSearchResult(result)) return filter === SearchFilterOptions.Attachments;
    return false;
}

function SearchResultMultiplex(
    { result, marks }: { result: SearchResult; marks: [string, string]; },
): React.JSX.Element {
    const filter = useAppSelector(selectFilter);

    if (!filterMatches(result, filter)) return <></>;
    if (isMessageSearchResult(result)) {
        return <MessageSearchResultCard result={result} marks={marks} />;
    }
    if (isBondSearchResult(result)) return <BondSearchResultCard result={result} marks={marks} />;
    if (isCallSearchResult(result)) return <CallSearchResultCard result={result} marks={marks} />;
    if (isAttachmentSearchResult(result)) {
        return <AttachmentSearchResultCard result={result} marks={marks} />;
    }
    return <></>;
}
interface SearchResultCardProps {
    bondId: d.BondId;
    messageId?: d.MessageId;
    dateTime?: number;
    snippet?: string;
    marks?: [string, string];
    type?: string;
}

function SearchResultCard(
    { bondId, messageId, dateTime, snippet, marks, type }: SearchResultCardProps,
): React.JSX.Element {
    const isMobile = isMobileBrowser();
    const bondTitle = useSelectorArgs(selectBondTitle, bondId);
    const { navigatePush } = useViewStackNavigate();

    const titleOnly = bondTitle.title;
    const emojiOnly = bondTitle.emoji;

    const bondClasses = classNames("c-bond-card c-bond-card--search", {
        "c-bond-card--desktop": !isMobile,
    });

    const thumbClasses = classNames("c-thumb", {
        "c-thumb--desktop": !isMobile,
    });

    const thumbTimeClasses = classNames("c-thumb__label", {
        "c-thumb__label--desktop": !isMobile,
    });

    const thumbIconClasses = classNames("c-thumb__icon", {
        "c-thumb__icon--desktop": !isMobile,
    }, type && type !== "bond" && [`c-thumb__icon--${type}`]);

    const thumbEmojiClasses = classNames("c-thumb__search-emoji", {
        "c-thumb__search-emoji--desktop": !isMobile,
    });

    const summaryClasses = classNames("c-bond-card__summary u-clamp_1", {
        "c-bond-card__summary--desktop": !isMobile,
    });

    return (
        <div
            className={bondClasses}
            onClick={() => {
                navigatePush(viewUtils.singleBond(bondId, messageId));
            }}
        >
            <div className={thumbClasses}>
                {dateTime && (
                    <time
                        className={thumbTimeClasses}
                        dateTime={new Date(dateTime).toLocaleString()}
                    >
                        <TimeAgo from={dateTime} live />
                    </time>
                )}
                {type === "bond" ? (
                    <>
                        <div className={thumbEmojiClasses}>{emojiOnly}</div>
                        <div className="c-thumb__mask"></div>
                        <div className="c-thumb__background">{emojiOnly}</div>
                    </>
                ) : <div className={thumbIconClasses} />}
            </div>
            <div className="c-bond-card__main">
                <div className="c-bond-card__name u-clamp_1">{titleOnly}</div>
                {snippet && marks &&
                    (
                        <div className={summaryClasses}>
                            {parseHTML(snippet, marks)}
                        </div>
                    )}
            </div>
        </div>
    );
}

function MessageSearchResultCard(
    { result, marks }: { result: MessageSearchResult; marks: [string, string]; },
): React.JSX.Element {
    const content = result.content;
    const bondId = content.bondId;
    const message = useSelectorArgs(selectMessage, content.messageId);
    const author = useSelectorArgs(selectUserName, result.content.authorId) || "Unknown";
    const bond = useSelectorArgs(selectBondById, bondId);

    useInterestedBond(bondId);
    useInterestedChannel(bond?.channelId);

    if (!isOfficialChatMessage(message)) {
        return <div>Not a chat msg {content.messageId}.</div>;
    }

    if (!bond) <div>Message Search Error: no Bond with id:{bondId}.</div>;

    const defaultSnippet = message.content.message || "no snippet";
    const snippet = author.concat(": ", content.highlight.text.snippet) || defaultSnippet;

    return (
        <SearchResultCard
            bondId={bondId}
            messageId={message.id}
            snippet={snippet}
            dateTime={message.serverRxTs}
            marks={marks}
            type={"message"}
        />
    );
}

function BondSearchResultCard(
    { result, marks }: { result: BondSearchResult; marks: [string, string]; },
): React.JSX.Element {
    const content = result.content;
    const bondId = content.bondId;
    const bond = useSelectorArgs(selectBondById, bondId);

    useInterestedBond(bondId);

    if (!bond) <div>Bond Search Error: no Bond with id:{bondId}.</div>;

    const defaultSnippet = bond?.knowledge.summary || "no snippet";
    const snippet = result.content.highlight.summary.snippet ||
        result.content.highlight.detailedSummary.snippet ||
        result.content.highlight.summary.snippet;

    return (
        <SearchResultCard
            bondId={bondId}
            snippet={snippet || defaultSnippet}
            marks={marks}
            type={"bond"}
        />
    );
}

function CallSearchResultCard(
    { result, marks }: { result: CallSearchResult; marks: [string, string]; },
): React.JSX.Element {
    const content = result.content;
    const bondId = content.bondId;
    const message = useSelectorArgs(selectMessage, content.startMessageId);
    const bond = useSelectorArgs(selectBondById, bondId);
    const call = useSelectorArgs(selectCallById, content.callId);

    useInterestedBond(bondId);
    useInterestedCalls(call?.id);

    if (!isCallStartMessage(message)) {
        return <div>Not a call start message {content.startMessageId}.</div>;
    }

    if (!bond) <div>Call Search Error: no Bond with id:{bondId}.</div>;

    const snippet = content.highlight.title.snippet || content.highlight.shortSummary.snippet ||
        content.highlight.detailedSummary.snippet;
    const defaultSnippet = call?.knowledge.summary || "no snippet";

    return (
        <SearchResultCard
            bondId={bondId}
            messageId={message.id}
            snippet={snippet || defaultSnippet}
            dateTime={message.serverRxTs}
            marks={marks}
            type={"call"}
        />
    );
}

function AttachmentSearchResultCard(
    { result, marks }: { result: AttachmentSearchResult; marks: [string, string]; },
): React.JSX.Element {
    const content = result.content;
    const bondId = content.bondId;
    const message = useSelectorArgs(selectMessage, content.messageId);
    const bond = useSelectorArgs(selectBondById, bondId);
    useInterestedBond(bondId);
    useInterestedChannel(bond?.channelId);

    if (!isOfficialChatMessage(message)) {
        return (
            <div>Not an attachment message {content.messageId} {message?.type ?? "undefined"}.</div>
        );
    }

    if (!bond) <div>Attachment Search Error: no Bond with id:{bondId}.</div>;

    const snippet = content.highlight.shortDescription.snippet ||
        content.highlight.longDescription.snippet ||
        content.highlight.filename.snippet;
    const defaultSnippet = "no snippet"; // todo fetch attachment knowledge

    return (
        <SearchResultCard
            bondId={bondId}
            messageId={message.id}
            snippet={snippet || defaultSnippet}
            dateTime={message.serverRxTs}
            marks={marks}
            type={"attachment"}
        />
    );
}
